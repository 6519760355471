"use client";

import { useLayoutEffect } from "react";
import { usePathname } from "next/navigation";

import { useContentfulBreadcrumbs } from "@/store/clientStore";

export const BreadcrumbHelper = ({ breadcrumbs = [] }) => {
  const { setContentfulBreadcrumbs } = useContentfulBreadcrumbs();
  const pathname = usePathname();

  useLayoutEffect(() => {
    setContentfulBreadcrumbs({
      pathname,
      breadcrumbs,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
